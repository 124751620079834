<template>
    <countdown :time="totalMilliseconds">
        <template v-slot="{ totalDays, hours, minutes, seconds }">
            <span :class="spanClass">
                <span v-if="totalDays > 0" class="mr-1">{{ totalDays }}d</span>
                <span v-if="totalDays > 0 || hours > 0" class="mr-1">{{ hours }}h</span>
                <span class="mr-1">{{ minutes }}m</span>
                <span class="mr-1">{{ seconds }}s</span>
            </span>
        </template>
    </countdown>
    <!-- <span>
        <span v-if="totalDays > 0" class="mr-1">{{ totalDays }}d</span>
        <span v-if="hours > 0" class="mr-1">{{ hours }}h</span>
        <span v-if="minutes > 0" class="mr-1">{{ minutes }}m</span>
        <span v-if="seconds > 0" class="mr-1">{{ seconds }}s</span>
    </span> -->
</template>

<script>

    // const MILLISECONDS_SECOND = 1000;
    // const MILLISECONDS_MINUTE = 60 * MILLISECONDS_SECOND;
    // const MILLISECONDS_HOUR = 60 * MILLISECONDS_MINUTE;
    // const MILLISECONDS_DAY = 24 * MILLISECONDS_HOUR;

    export default {
        name: 'BT-Countdown',
        data: function() {
            return {
                isCounting: false,
                endTime: 0,
                requestId: 0
            }
        },
        props: {
            autoStart: {
                type: Boolean,
                default: true
            },
            spanClass: {
                type: String,
                default: 'text-caption'
            },
            interval: {
                type: Number,
                default: 1000,
                validator: value => value >= 0
            },
            tag: {
                type: String,
                default: 'span'
            },
            /**
             * The date in the future to countdown to
             * utc string format
             */
            value: null
        },
        computed: {
            endDate() {
                return this.$BlitzIt.auth.formUTCDateTime(this.value)
            },
            totalMilliseconds() {
                return this.endDate.diff(this.$BlitzIt.auth.createRawUTC()).as('milliseconds');
            },
            // days() {
            //     return Math.floor(this.totalMilliseconds / MILLISECONDS_DAY)
            // },
            // hours() {
            //     return Math.floor((this.totalMilliseconds % MILLISECONDS_DAY) / MILLISECONDS_HOUR)
            // },
            // minutes() {
            //     return Math.floor((this.totalMilliseconds % MILLISECONDS_HOUR) / MILLISECONDS_MINUTE)
            // },
            // seconds() {
            //     return Math.floor((this.totalMilliseconds % MILLISECONDS_MINUTE) / MILLISECONDS_SECOND)
            // },
            // milliseconds() {
            //     return Math.floor(this.totalMilliseconds % MILLISECONDS_SECOND)
            // },
            // totalDays() {
            //     return this.days
            // },
            // totalHours() {
            //     return Math.floor(this.totalMilliseconds / MILLISECONDS_HOUR)
            // },
            // totalMinutes() {
            //     return Math.floor(this.totalMilliseconds / MILLISECONDS_MINUTE)
            // },
            // totalSeconds() {
            //     return Math.floor(this.totalMilliseconds / MILLISECONDS_SECOND)
            // }
        },
        // watch: {
        //     $props: {
        //         deep: true,
        //         immediate: true,
        //         handler() {
        //             this.totalMilliseconds = this.value;
        //             this.endTime = 
        //         }
        //     }
        // },
        methods: {
            // getEndDate() {
            //     // if (!this.value) {
            //     //     return this.$BlitzIt.auth.formUTC()
            //     // }
                
            //     return this.$BlitzIt.auth.formUTC(this.value)
            // }
        }
    }
</script>